@use 'styles/theme/theme' as theme;
.fas {
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    overflow-x: scroll;
  }


  &__section {
    margin-top: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
    }
    .count {
      display: inline-flex;
      align-items: center;
      &:before {
        content: ' ';
        margin: 0 6px;
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: theme.$secondaryGrey;
      }
    }

    @media screen and (max-width: 1024px) {
      justify-content: center;
      margin-top: 26px;
      &::before, &::after {
        display: inline-block;
        vertical-align: middle;
        content: "";
        border-top: 1px solid theme.$tertiaryGrey;
        flex: 1;
        margin: 0 10px;
      }
    }
  }
}
