@use 'styles/theme/theme' as theme;

.cfb {
  display: flex;
  flex-direction: column;
  gap: 0 12px;
}

.cfbInline {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  & > div {
    width: 50%;
    margin-top: 0;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
  }
}
