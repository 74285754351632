@import 'styles/colors';
@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @include up($old_md) {
    align-items: center;
  }
}

.appBar {
  z-index: 999;
  background-color: $white;
  color: #6c6c6c;
  box-shadow: none;
}

.appBarWithoutBreadcrumbs {
  border-bottom: solid 1px $lightGrey;
}

.rootContainer {
  padding-left: 0px;
  padding-right: 0px;
  @include up($old_lg) {
    //max-width: $old_lg;
  }
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-width: $old_lg;
  min-height: 85vh;
  width: 100%;
  @include up($old_lg) {
    min-width: 1270px;
  }
  @include down($old_md) {
    margin-top: 0;
  }
}

.maxWidthNone {
  max-width: none;
}

.layoutContent {
  padding: 20px 30px;
  position: relative;
  @include down($old_md) {
    padding: 16px;
  }
}

.layoutContentReg {
  padding: 0 30px;
  position: relative;
  @include down($old_md) {
    padding: 16px;
  }
}

.noPadding {
  padding: 0;
}

.logoBreadcrumbWrapper {
  width: 100%;
  z-index: 112;
  background: $white;
  position: sticky;
  top: var(--page-content-static-offset);
  padding-top: 3px;
  &_nonSticky {
    position: relative;
    top: 0;
  }
}

.logoBreadcrumbSection {
  margin: auto;
  max-width: 1310px;
  display: flex;
  overflow: hidden;
  padding: 0 15px;
  height: 30px;
  align-items: center;
  position: relative;

  // TODO Check if needed. Shows up as a " in right section of mobile screen rn
  //&::after {
  //  content: '" "';
  //  position: absolute;
  //  display: inline-block;
  //  right: 0;
  //  width: 20px;
  //  text-align: center;
  //  padding-left: 1em;
  //  white-space: pre;
  //  @include up(1024px) {
  //    display: none;
  //  }
  //}
}

.breadcrumbs {
  overflow: hidden;
  width: 500px;
  overflow-x: scroll;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 1px;
    width: 1px;
  }
  & > nav > ol {
    flex-wrap: nowrap;
  }
  @include up(768px) {
    width: 700px;
  }
  @include up(1025px) {
    width: 1200px;
  }
  @include up(1440px) {
    width: 1800px;
  }
}