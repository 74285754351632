@use 'styles/theme/theme' as theme;

.btt {
  position: relative;
  @media (min-width: 1024px) and (max-width: 1280px) {
    margin: 0 -16px;
  }

  &__testimonials, &__trustedBy {
    margin-top: 16px;
    @media screen and (max-width: 1024px) {
      padding-left: 16px;
    }
  }

  &__trustedBy {
    @media screen and (max-width: 600px) {
      margin-top: 0px;
      margin-bottom: 0;
    }
  }
}

.scrollLeftIndicator, .scrollRightIndicator {
  border-right: transparent;
  cursor: pointer;
  height: 58px;
  border: 1px solid theme.$borderGrey;
  background: linear-gradient(90deg, #FFF -21.86%, #FFF 68.51%);
  z-index: 1;

  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}

.scrollLeftIndicator {
  box-shadow: 10px 0px 8px -6px rgba(0, 0, 0, 0.3);
  border-right: transparent;

  svg {
    margin-left: 7px;
  }
}

.scrollRightIndicator {
  border-left: transparent;
  box-shadow: -13px 0px 8px -6px rgba(0, 0, 0, 0.3);
  svg {
    margin-right: 7px;
  }
}

.trustedByBlock {
  @media screen and (max-width: 600px) {
    border: 1px solid red;
    padding: 8px 0px 8px 8px;
    border-right-style: none;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border: 1px solid theme.$borderGrey;
  }
}
