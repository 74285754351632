@use 'styles/theme/theme' as globalTheme;

.pageH1 {
  display: flex;
  grid-gap: 8px;
  align-items: center;
  &__image {
    width: 38px;
    height: 38px;
    border: 1px solid var(--current-theme-header-border-color);
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--inverse-theme-background-color);
    flex-shrink: 0;
  }
  &__title {
    flex-grow: 1;
  }
}

.scrollablePageH1 {
  border: 1px solid var(--current-theme-header-border-color);
  padding: 10px;
  border-radius: 4px;
}

.title {
  font-size: inherit;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: globalTheme.$base-font-family;
  color: var(--current-theme-primary-text-color);
  &_part {
    position: relative;
    margin-bottom: 14px;
    display: inline-block;
    &__with_click {
      cursor: pointer;
    }
    &:after {
      content: attr(data-headline);
      position: absolute;
      font-size: 11px;
      font-weight: 400;
      bottom: -12px;
      left: 0;
    }
  }
}
