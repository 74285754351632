@import 'styles/theme/theme';

.shareEntity {
  &__label {
    display: flex;
    cursor: pointer;
    border-radius: 64px;
    color: $secondaryGrey;
    background: $whiteSmoke;
    border: 1px solid $borderGrey;
    padding: 8px 15px;
    line-height: 16px;
    justify-content: center;

    &.noLabel {
      padding: 8px;
    }

    .shareIcon {
      fill: $primaryGrey;
    }
    .text {
      margin-left: 5px;
      color: $primaryGrey;
    }

    &:hover {
      background: $darkerSmoke;
    }

    @media screen and (max-width: 600px) {
      border-radius: 50%;
      width: 34px;
      height: 34px;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 1px solid transparent;
    }
  }

  &.shareEntity .iconBtn {
    display: none;
    width: 42px;
    height: 42px;

    &.is_visible {
      display: block;
    }

    &:hover {
      svg {
        fill: $blue;
      }
    }

    svg {
      fill: $primaryGrey;
    }
  }

  &__modalWrapper {
    width: 910px !important;

    @media screen and (max-width: 1024px) {
      width: 550px !important;
    }

    @media screen and (max-width: 600px) {
      width: 100% !important;
    }
  }

  &__modalBody {
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  &__modalInfo {
    display: flex;
    padding: 16px;
    background-color: $whiteSmoke;
    border-radius: $border-radius-small;
    column-gap: 24px;
  }

  &__modalActions {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;

    .socialBtn {
      display: flex;
      align-items: center;
      border: 1px solid $tertiaryGrey !important;
      display: flex;
      padding: 10px 16px 10px 14px !important;
      border-radius: 200px;
      background-color: $white;
      cursor: pointer;

      svg {
        margin-right: 6px;
        width: 20px;
        height: 18px;
      }

      .socialText {
        font-weight: $font-weight-medium;
        font-size: $font-size-14;
        color: $primaryGrey;
      }

      &:hover {
        box-shadow: $btnBoxShadowHover;
      }

      &:active {
        box-shadow: $btnBoxShadowActive;
      }
    }
  }

  .whatsappIcon {
    margin-right: 6px;
  }
}

.contributionType {
  margin-top: 6px;
}

.upcomingPerformance {
  margin-top: 10px;
}

.musicalWork + .musicalWork {
  margin-top: 4px;
}

.performanceInfo {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  row-gap: 2px;
  align-items: baseline;
}

.details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.locationUrl {
  margin-top: 8px;
}

.logo {
  display: flex;
  position: absolute;
  right: 30px;
}

.icon {
  margin-right: 6px;
}

@media only screen and (max-width: 1024px) {
  .shareEntity {
    &__modalActions {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (max-width: 600px) {
  .shareEntity {
    &.is_visible {
      display: none;
    }
  }
}

.iconBtnSmall {
  background: transparent !important;
  padding: 4px;
  color: $white;
  text-align: center;
  width: 26px;
  height: 26px;

  svg {
    fill: $white;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.58) !important;
    border: 1px solid $white;
    box-shadow: none !important;
  }
}
