@import './breakpoints';

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin down($size) {
  @if $size == 'xs' {
    @media (max-width: $sm) { @content; }
  } @else if $size == 'sm' {
    @media (max-width: $old_md) { @content; }
  } @else if $size == 'md' {
    @media (max-width: $old_lg) { @content; }
  } @else if $size == 'lg' {
    @media (max-width: $old_xl) { @content; }
  } @else {
    @media (max-width: $size) { @content; }
  }
}

@mixin up($size) {
  @media (min-width: $size) { @content; }
}

@mixin hideDown($size: $old_md) {
  @include down($size) {
    display: none;
  }
}

@mixin hideUp($size: $old_md) {
  @include up($size) {
    display: none;
  }
}

/*
0 - 375px:      Phone
375px - 900px:    Tablet portrait
900 - 1024px:   Tablet landscape
[1024 - 1440] is where our normal styles apply
1800px + :      Big desktop
- phone
- tab-port
- tab-land
- desktop
- big-desktop
*/
@mixin respond($breakpoint) {
  @if $breakpoint ==phone {
    @media only screen and (max-width: 375px) {
      @content
    }

    ; //600px
  }

  @if $breakpoint ==tab-port {
    @media only screen and (max-width: 900px) {
      @content
    }

    ; //900px
  }

  @if $breakpoint ==tab-land {
    @media only screen and (max-width: 1024px) {
      @content
    }

    ; //1024px
  }

  @if $breakpoint ==desktop {
    @media only screen and (max-width: 1440px) {
      @content
    }

    ; //1440px
  }

  @if $breakpoint ==big-desktop {
    @media only screen and (min-width: 1800px) {
      @content
    }

    ; //1800px
  }
}