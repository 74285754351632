@use 'styles/theme/theme' as theme;

.root {
  width: 100%;
  display: grid;
  grid-gap: 12px;
  margin-top: 12px;
  grid-template-columns: auto min-content;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  
  &__white {
    background: theme.$white;
    border: 1px solid theme.$borderGrey;
  }

  &__blue {
    background: theme.$blueHover;
    border: 1px solid theme.$blueLightBorder;
  }

  &__orange {
    background: theme.$orangeLight;
    border: 1px solid theme.$orangeLightBorder;
  }

  &:not(:hover) {
    .link {
      background: transparent;
      border: 1px solid transparent;
      &_text {
        color: theme.$primaryGrey;
      }
      svg {
        fill: theme.$primaryGrey !important;
      }
    }
  }
  &:hover {
    .link {
      &_text {
        &__auto_hide {
          visibility: visible;
        }
      }
    }
  }
}

.link {
  white-space: nowrap;
  &_text__auto_hide {
    visibility: hidden;
  }
}