@import 'styles/theme/theme';

.lsVodProductionDetail {
  padding: 10px 0;
  width: 100%;
  &__item + &__item {
    padding-top: 10px;
  }
}

.lsVodInformation {
  cursor: pointer;
  padding-bottom: 15px;

  &:last-child {
    border-bottom: none;
  }

  .lsVodCompanyName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .lsVodMediaWrapper {
      width: 44px;
      height: 36px;
      margin-right: 8px;
      border: 1px solid $tertiaryGrey;
      border-radius: 50%;

      .icon {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
    }

    .lsVodMainDetails {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;

      p {
        margin: 0;
      }

      .lsVodChannel {
        .moreInfo {
          display: flex;
          flex-wrap: wrap;

          .time {
            margin-left: 2px;

            &::before {
              content: ' | ';
            }
          }
        }
      }
    }
  }
}
