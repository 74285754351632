@use 'styles/theme/theme' as globalTheme;

.root {
  border: 1px solid var(--current-theme-card-border-color);
  display: flex;
  align-items: center;
  border-radius: 45px;
  overflow: hidden;
  cursor: pointer;
  max-width: 400px;

  &.inline {
    display: inline-flex;
  }
  
  &_btn {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    height: 100%;

    &:hover {
      svg {
        fill: var(--current-theme-cta-text-color);
      }
    }
  }

  &_primary {
    background: var(--current-theme-background-color);
    color: var(--current-theme-primary-text-color);
    padding: 5px 16px 5px 16px;
    white-space: nowrap;

    svg {
      fill: var(--current-theme-primary-text-color);
    }

    &:hover {
      color: var(--current-theme-cta-text-color);
    }
  }
  &_secondary {
    flex-grow: 1;
    background: var(--current-theme-card-border-color);
    color: var(--current-theme-secondary-text-color);
    padding: 8px 16px 8px 18px;
    grid-gap: 0px;

    svg {
      fill: var(--current-theme-cta-text-color);
    }
  }
  &_tertiary {
    flex-grow: 1;
    background: globalTheme.$blue;
    color: globalTheme.$white;
    padding: 8px 16px 8px 18px;
    border-radius: 0;
    border: 0;

    svg {
      fill: var(--current-theme-primary-text-color);
    }

    &:hover {
      svg {
        fill: var(--current-theme-primary-text-color);
      }
    }
  }
}

.drawerHeader {
  padding: 0 !important;
}

.menuHeader {
  background: globalTheme.$borderGrey;
  padding: 12px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;

  &_page_type {
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }
}

.menu {
  width: 100%;

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    &_item {
      padding: 14px 14px 14px 11px;
      border-left: 5px solid globalTheme.$borderGrey;
      background: globalTheme.$borderGrey;
      cursor: pointer;

      &:hover {
        border-left-color: globalTheme.$white;
      }

      &.active {
        border-left-color: globalTheme.$orange;
        background: globalTheme.$white;
      }
    }
  }
}
