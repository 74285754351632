@use 'styles/theme/theme' as theme;

.noResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  &__searchIcon {
    color: theme.$primaryGrey;
  }

  &__title {
    margin: 8px 0px;
  }

  &__info {
    line-height: 16px;
    text-align: center;
  }
}