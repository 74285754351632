@use 'styles/theme/theme' as *;

.tooltipArrow {
  color: white;
  &:before {
    border: 1px solid $borderGrey;
  }
}

.faqBtn {
  color: $primaryGrey;
}

.section {
  display: block;
  .rightIcon {
    opacity: 0;
  }
  .sectionTitle {
    color: $primaryGrey;
  }

  &:hover {
    .sectionTitle {
      color: $blue;
    }
    .faqRow {
      color: $primaryGrey;
    }

    .rightIcon {
      opacity: 1;
    }
  }
}

.tooltip {
  border: 1px solid $borderGrey;
  background-color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 16px;
  max-width: 375px;
  border-radius: 12px;
}

.sectionContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  white-space: pre-wrap;
}

.contentContainer {
  list-style-position: outside;
  padding-inline-start: 20px;
  margin: 6px auto;
  ::marker {
    color: $secondaryGrey;
    font-size: 10px;
  }
  li {
    font-size: 12px;
  }
}

.modalRoot {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.modal {
  width: auto !important;
}

.modalContent {
  padding: 10px 16px;
  max-width: 463px;

  @media screen and (max-width: 600px) {
    max-width: unset;
  }
}

.ctaText {
  font-size: 12px;

  .ctaIcon {
    opacity: 0;
  }
  &:hover {
    .ctaIcon {
      opacity: 1;
    }
  }
}

.faqContent {
  section + section {
    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background: $borderGrey;
      margin: 8px 0;
    }
  }
}
