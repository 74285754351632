.entityWrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .control {
    min-width: 250px;
  }
}

.actionsButtonWithDate {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    margin-bottom: 20px;
    margin-top: 0;
    & > div {
      margin-bottom: 0;
    }
  }

  &__datePicker {
    width: 100% !important;
    margin-right: 0 !important;
    max-width: 100% !important;
  }
}