.control {
  margin-right: 15px;
  max-width: 340px;
  min-width: 190px;
  margin-bottom: 15px;
  width: 100%;
  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    max-width: 100%;
  }
  :global {
    & .MuiOutlinedInput-root {
      border-radius: 8px;
      &.Mui-focused fieldset {
        border-color: #0067c5;
      }
    }

    & label.Mui-focused {
      color: #0067c5;
    }
  }
}
