@import 'styles/theme/theme';

.buttonTabs {
  display: flex;
  border: 1px solid $borderGrey;
  width: max-content;
  border-radius: 4px;

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 14px;
    width: 170px;
    height: 42px;
    background: $white;
    color: $secondaryGrey;
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;

    &.active {
      background: $blueLight;
      border: 1px solid $blue;
      border-radius: 4px;
      color: $blue;
    }
  }
}
