@import 'styles/colors';

.link {
  font-size: inherit;
  text-decoration: none;
  line-height: 1.3;
  & span {
    line-height: 1.3;
  }
}

.disabled {
  color: $textGray;
}

.textType {
  color: $text;
  &:hover {
    color: $blue;
    background-color: transparent;
  }
}

.iconLink {
  display: inline-flex;
  align-items: center;
}

.underlined {
  text-decoration: underline;
}

.iconBox {
  margin-right: 8px;
  min-width: 1rem;
  min-height: 1rem;
  * > svg {
    vertical-align: middle;
    min-width: 1rem;
    min-height: 1rem;
  }
}
