@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .button {
    border-color: theme.$borderGrey;
    border-radius: 4px;
    box-shadow: unset;
  }
}

.pro {
  color: theme.$orange;
  margin-left: 2px;
}
