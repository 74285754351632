@import 'styles/theme/theme';

.sourceName {
  font-size: $font-size-16;
  font-weight: 500;
  color: $primaryGrey;

  .isLive {
    padding: 5px;
    color: $white;
    background: $red;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 8px;
    font-size: $font-size-12;
  }
}