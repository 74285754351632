@use 'styles/theme/theme' as theme;

.castingToolLabel {
  margin-left: 3px;
}

.castingToolCTA {
  min-width: auto;
  max-height: fit-content;
  height: fit-content;
  color: theme.$secondaryGrey;
  font-size: theme.$font-size-12;

  &:hover {
    color: theme.$secondaryGrey;
  }
}

.root {
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0px 16px;
  border-radius: 5px;
  background: theme.$orangeLight;
  cursor: pointer;
  &__divider {
    height: 34px;
    margin: 0 12px;
    border-right: 1px solid theme.$tertiaryGrey;
  }
  &__separatorItem {
    &::before {
      color: theme.$secondaryGrey;
      padding: 0 4px;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 0 0 0 10px;
    height: 47px;
    .root__link {
      max-width: 95px;
      word-break: break-word;
      white-space: normal;
    }
    .root__divider {
      height: 47px;
    }
    .root__separatorItem {
      font-size: 10px;
      &::before {
        padding: 0 2px;
      }
    }
  }
}
