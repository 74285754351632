@use 'styles/theme/theme' as globalTheme;

.header {
  background-color: var(--current-theme-background-color);
}

.section {
  &__separator {
    border-bottom: 1px solid var(--current-theme-header-border-color);
  }
  &__container {
    max-width: 1280px;
    margin: auto;
    padding: 18px 0 16px;

    &.noEdgePadding {
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (max-width: 1280px) {
      padding: 18px 16px 16px;

      &.noEdgePadding {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &__castingToolPromo {
    margin-top: 42px;
  }
  & + & {
    .section__container {
      padding-top: 0;
    }
  }
  &:first-of-type:not(.section__separator) {
    .section__container {
      padding-top: 0;
    }
  }
}

.breadcrumbs {
  padding-top: 18px;
  padding-bottom: 24px;
}

.entityStatus {
  margin-bottom: 16px;
}

.pageHeader {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr max-content;
  grid-gap: 8px;
}

.navigations {
  margin-top: 20px;

  @media screen and (max-width: 1280px) {
    margin: 20px -16px 0;
  }
}

.stickyHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  z-index: 115;
  background: globalTheme.$white;
  border-bottom: 1px solid globalTheme.$tertiaryGrey;

  &__container {
    max-width: 1280px;
    margin: auto;
    padding: 16px 0 12px;
    @media screen and (max-width: 1280px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  & + .section {
    .section__container {
      padding-top: 0;
    }
  }
}

.stickyHeaderContent {
  display: flex;
  @media screen and (max-width: 1280px) {
    flex-direction: column;
    grid-gap: 12px;
  }
  &__entity {
    margin-right: 12px;
    padding-right: 12px;
    border-right: 1px solid globalTheme.$tertiaryGrey;
    @media screen and (max-width: 1280px) {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
  }
  &__menu {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }
}
