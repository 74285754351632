@use 'styles/theme/theme' as theme;

.root {
  &__testimonials,
  &__trustedBy {
    margin-top: 16px;
    @media screen and (max-width: 1024px) {
      padding-left: 16px;
    }
  }

  &__tabsContainer {
    width: 100%;
    background: theme.$white;
    padding: 8px;
    display: flex;
    justify-content: center;
  }
  &__tabsContainerSticky {
    @media screen and (max-width: 600px) {
      margin-top: -2px;
      position: sticky;
      top: 152px;
      z-index: 115;
    }
    &_loggedOut {
      @media screen and (max-width: 600px) {
        top: 175px;
      }
    }
  }

  &__mainContainer {
    margin-top: 32px;
    display: flex;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      margin-top: 16px;
    }

    &_left,
    &_right {
      flex: 1;
      width: 50%;
    }

    &_right {
      padding-left: 30px;
      overflow: hidden;

      @media screen and (max-width: 1024px) {
        padding: 0px;
        width: 100%;
      }
    }
    &_left {
      padding-right: 30px;
      margin-top: 1px;
      border-right: 1px solid theme.$tertiaryGrey;

      @media screen and (max-width: 1024px) {
        width: 100%;
        padding: 0 16px;
      }

      .title {
        margin-bottom: 28px;
      }
    }

    &.root__mainContainerMobile {
      flex-direction: column-reverse;
      gap: 16px;
      margin-top: 8px;

      .root__mainContainer_right {
        margin-top: 0px;
      }
    }
  }

  &__explorePages {
    margin-top: 24px;
    @media screen and (max-width: 600px) {
      padding: 0 16px;
    }
  }

  &__performancesToday {
    margin-top: 44px;
    @media screen and (max-width: 1204px) {
      margin-top: 28px;
    }
  }
}
