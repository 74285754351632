@import 'styles/theme/theme';

.wrapper {
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;

  &__subtitle {
    margin-bottom: 10px;
  }

  .content {
    width: 100%;
  }

  .actions {
    border-top: 1px solid $tertiaryGrey;
    background: $white;
    display: flex;
    padding: 15px 0;
    justify-content: space-between;
    width: 100%;
    padding: 8px 0px 8px 20px;
    max-height: 60px;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.discoverEntity {
  display: flex;
  justify-content: center;
  font-size: 12px;
  width: max-content;
  margin: 0 auto;
  border-radius: 0;
  &:hover,
  &:active {
    background-color: transparent;
    box-shadow: none;
  }
}

.stats {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 18px;
  justify-content: center;

  &__section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    &_title {
      margin-left: 5px;
      white-space: nowrap;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .wrapper {
    top: 70px;
    position: relative;
    margin-top: 30px;
    .content {
      margin-bottom: 60px;
    }

    .actions {
      position: fixed;
    }
  }

  .concluding {
    margin-bottom: 20px;
  }
}
