@use 'styles/theme/theme' as theme;

.orgActionsList {
  list-style: none;
  padding: 0 16px;
  &__item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 22.5px 0;
    & + & {
      border-top: 1px solid theme.$borderGrey;
    }
    .icon {
      fill: theme.$primaryGrey;
    }
    .link {
      color: theme.$primaryGrey;
    }
    .shareAccess {
      padding: 0;
    }
    .shareEntity {
      span {
        svg {
          width: 24px !important;
          height: 24px !important;
          fill: theme.$primaryGrey;
        }
        span {
          margin-left: 12px;
          align-self: center;
        }
      }
    }
  }
  .watchOptions {
    padding: 0;
  }
}