@use 'styles/theme/theme' as theme;

.root {
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 1;
}
  
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  margin-top: 8px;
  z-index: 1;
  background-color: theme.$white;
  min-width: 200px;
  box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12), 0 11px 15px rgba(0, 0, 0, 0.2);
  border-radius: theme.$border-radius-small;

  @media (max-width: 800px) {
    left: -190px;
  }

  & > * {
    border-radius: 0;
    justify-content: flex-start;
  }
  
  & > :first-child {
    border-top-left-radius: theme.$border-radius-small;
    border-top-right-radius: theme.$border-radius-small;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  & > :last-child {
    border-bottom-left-radius: theme.$border-radius-small;
    border-bottom-right-radius: theme.$border-radius-small;
    border-bottom: 0px;
  }
}

.iconWithoutLabel {
  border-radius: 50%;
  border: 1px solid theme.$borderGrey;
  background: theme.$whiteSmoke;
  padding: 9px 16px;
}

.item {
  width: 100%;
  padding: 10px;
  color: inherit;
  border-bottom: 1px solid theme.$tertiaryGrey;
  &:hover {
      background-color: theme.$blueLight;
      color: theme.$blue;
      span {
          color: theme.$blue;
      }

      svg {
          fill: theme.$blue;
      }
  }
  .icon {
      margin-left: 2px;
  }
}

.item .icon svg {
  fill: theme.$primaryGrey !important;
}

@media only screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-top: 0px;
    margin-left: 0;
    border-radius: 0;
    left: 0px;
    box-shadow: none;

    & > :first-child {
      border-radius: 0;
    }
    & > :last-child {
      border-radius: 0;
      border-bottom: 0px;
    }
    
    & > * {
      border-radius: 0;
      border-bottom: 1px solid theme.$borderGrey;
    }
  }

  .item { 
    padding: 20px;
    span {
      display: flex;
        svg {
          fill: theme.$primaryGrey !important;
        }
    }
    &:hover {
        background-color: transparent;
    }
  }
}
