@use 'styles/theme/theme' as theme;

.wrapper {
  position: relative;
  &__withDottedBorder {
    border: 2px dashed theme.$tertiaryGrey;
    border-radius: 4px;
  }
}

.bannerLoading {
  width: 100%;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 0 16px;
  column-gap: 5px;
}

.mediaWidget {
  &.productionMediaWidget {
    margin: 0;
  }
  &__title,
  &__headline {
    position: relative;
    color: var(--current-theme-primary-text-color);
  }
}

.content {
  display: none;
  padding: 10px;
  width: 100%;
  bottom: 0;
  &_withPoster {
    width: calc(100% - 12px);
    margin-left: 12px;
    margin-left: 150px;
    width: calc(100% - 150px);
  }
}

.poster {
  background: theme.$white;
  width: 200px;
  height: 298px;
  overflow: hidden;
  border: 1px solid theme.$tertiaryGrey;

  img {
    width: 100%;
    display: block;
  }
}

.poster,
.media {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  .btnWrap {
    position: absolute;
    top: 12px;
    right: 12px;

    &__btn {
      color: theme.$white;
      background: rgba(theme.$black, 0.6);
      border: 0;

      svg {
        fill: theme.$white;
      }

      &:hover {
        border: 0;
        background: rgba(theme.$black, 1);
        color: theme.$white;

        svg {
          fill: theme.$white;
        }
      }
    }
  }

  .mediaButtons {
    display: none;
  }

  &.typeVideo {
    background: theme.$black;

    & > div:first-child {
      height: 298px;
    }
  }

  &.typeImage {
    background: theme.$white;
    position: relative;
    height: 298px;

    .noscriptImage {
      width: 100%;
      height: 100%;
    }

    picture {
      height: 100%;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      max-height: 298px;
      display: block;
    }
  }
}

.allMediaWrapper {
  display: grid;
  grid-template-columns: 200px 1fr 1fr;
  grid-gap: 2px;
}

.posterMediaWrapper {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 4px;
}

.posterWrapper {
  width: 200px;
  height: 298px;
}

.landscapeMediaDoubleWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
}

.landscapeMediaWrapper {
  display: block;
}

.wrapper .shareEntity {
  display: none;
}

.mwHeader {
  display: grid;
  grid-template-columns: 200px 1fr 1fr;
  grid-gap: 4px;
  margin-bottom: 14px;
  & > span {
    display: flex;
    column-gap: 5px;
    margin-top: 15px;
    margin-left: 1px;
  }
}

.mwHeaderSectionBlock {
  margin-top: 40px;
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    padding: 0 16px;
    margin-top: 10px;
  }
  @media screen and (max-width: 1280px) {
    margin-top: 0px;
  }
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;

  &__icon {
    margin-bottom: 20px;
  }

  &__description {
    text-align: center;
    margin-bottom: 46px;
    &_video {
      width: 258px;
    }
    &_poster {
      width: 157px;
    }
  }

  &__video {
    border-right: 2px dashed theme.$tertiaryGrey;
  }

  &__withDashedBorder {
    border-left: 2px dashed theme.$tertiaryGrey;
  }

  &:hover {
    border-color: theme.$blue;
    background-color: theme.$blueLight;
    cursor: pointer;
  }
}

@media screen and (min-width: 1281px) {
  .allMediaWrapper {
    &__showBorder {
      grid-gap: 0px;
      & > :first-child {
        height: calc(100% + 4px);
        top: -2px;
        left: -2px;
        min-height: 298px;
        width: calc(100% + 4px);
        border: 2px dashed theme.$tertiaryGrey;
        z-index: 1;
        &:hover {
          &:has(.placeholder) {
            border: 0px;
          }
        }
      }

      &_video {
        & > :nth-child(2) {
          border: 2px dashed transparent;
          border-right-color: theme.$tertiaryGrey;
        }
      }
      &_photo {
        & > :last-child {
          border-left: 2px dashed theme.$tertiaryGrey;
        }
      }
    }
  }

  .placeholder {
    &__poster {
      &:hover {
        z-index: 1;
        border: 2px dashed theme.$blue;
      }
    }

    &__withDashedBorder {
      border-left: none;
      left: -2px;
      width: calc(100% + 6px);
      position: relative;
      &:hover {
        height: calc(100% + 4px);
        top: -2px;
        min-height: 298px;
        z-index: 1;
        border: 2px dashed theme.$blue;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .allMediaWrapper {
    display: block;
    position: relative;
  }
  .posterMediaWrapper {
    display: block;
    position: relative;
  }
  .placeholder {
    &__poster {
      border: 2px dashed theme.$tertiaryGrey;
    }
    &__description {
      &_poster {
        display: none;
      }
    }
    &__icon {
      &_poster {
        margin-bottom: 10px;
      }
    }
  }
  .mediaWidget {
    overflow: visible;
  }
  .posterWrapper {
    display: none;
  }
  .icon {
    svg {
      fill: theme.$white !important;
    }
  }
  .btnWrap {
    top: -18px;
    right: 28px;
    min-width: 0;
    position: absolute;
    &__btn {
      border: 1px solid theme.$secondaryGrey;
      border-radius: theme.$border-radius-large;
      background: theme.$primaryGrey;
      padding: 0 8px;
      min-width: 0;
      height: 28px;
      align-items: center;
      display: flex;
      column-gap: 5px;
      color: theme.$white;
    }
    .actionBtn {
      border: 1px solid theme.$secondaryGrey;
      border-radius: theme.$border-radius-large;
      background: theme.$primaryGrey !important;
      padding: 0 8px;
      min-width: 0;
      height: 28px;
      align-items: center;
    }
    .mediaIcon {
      margin-right: 0 !important;
      width: 26px;
      height: 26px;
    }
  }

  .content {
    display: block;
  }
  .gradient {
    background: linear-gradient(to bottom right, #000 0%, rgba(0, 44, 110, 0) 50%) bottom right / 50% 50% no-repeat,
      linear-gradient(to bottom left, #000 0%, rgba(0, 44, 110, 0) 50%) bottom left / 50% 50% no-repeat,
      linear-gradient(to top left, #000 0%, rgba(0, 44, 110, 0) 50%) top left / 50% 50% no-repeat,
      linear-gradient(to top right, #000 0%, rgba(0, 44, 110, 0) 50%) top right / 50% 50% no-repeat;
    filter: blur(27px);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  .background {
    background-color: rgba(theme.$black, 0.6);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  .poster {
    border: 1px solid theme.$tertiaryGrey;
    position: absolute;
    top: 69px;
    left: 12px;
    z-index: 1;
    width: 135px;
    height: 194px;
    &_primaryEntity {
      top: auto;
      bottom: -60px;
      left: 15px;
    }
    .btnWrap {
      right: 7px;
      left: 7px;
      position: absolute;
      bottom: 8px;
      margin-top: auto;
      height: fit-content;
      left: 50%;
      transform: translateX(-50%);
      width: fit-content;
    }
  }
  .media {
    height: 304px;
    .btnWrap {
      display: inline-flex;
    }
    .mediaButtons {
      display: block;
    }
    &.typeVideo {
      & > div:first-child {
        height: 304px;
      }
    }
  }

  .wrapper .shareEntity {
    display: block;
    position: absolute;
    right: 10px;
    bottom: -20px;
    height: auto;
    z-index: 1;
    border-radius: 8px;
  }
}

@media screen and (max-width: 600px) {
  .poster {
    width: 78px;
    height: 115px;
  }

  .mwHeaderSectionBlock {
    margin-left: 16px;
    width: calc(100% - 32px);
  }

  .media {
    height: 210px;
    &.typeVideo {
      & > div:first-child {
        height: 210px;
      }
    }
    &.typeImage {
      height: 210px;
    }
  }

  .content {
    &_withPoster {
      margin-left: 93px;
      width: calc(100% - 93px);
    }
  }
  
  .placeholder {
    &__icon {
      &_poster {
        margin-bottom: 5px;
      }
    }
    &:hover {
      border-color: none;
      background-color: transparent;
    }
  }
}

.mediaAttributionWithProduction {
  bottom: 48px !important;
}