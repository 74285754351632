@import 'styles/theme/theme';


.icon {
  color: $blue;
  margin-left: 5px;
  cursor: pointer;
  svg {
    font-size: $font-size-18;
  }
}