@use 'styles/theme/theme';

.root {
  text-align: center;
  margin: auto;
  padding: 8px;
  & > :last-child {
    padding-top: 8px;
  }
}

.subTitle {
  max-width: 259px;
  margin: 0 auto 8px;
  word-wrap: break-word;
  white-space: break-spaces;
}

.searchIcon {
  fill: #ECE9E9;
}

.smallStyle {
  display: grid;
  text-align: left;
  padding: 15px 30px;
  grid-column-gap: 15px;

  .subTitle {
    max-width: 100%;
    overflow: auto;
    margin: 0;
  }

  div{
    grid-column: 2;
  }

  .iconContainer {
    grid-column: 1;
    grid-row: 1 / 3;

    display: flex;
    align-items: center;

    .searchIcon {
      margin: auto;
    }
  }
}

.showOtherResults {
  display: block;
  background-color: theme.$borderGrey;
  padding: 18px;
  text-align: center;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}