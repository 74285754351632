@use 'styles/theme/theme' as theme;

.root {
  background: theme.$whiteSmoke;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &__footer {
    height: 48px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  &__production {
    padding: 8px 16px 0;
    background: rgba(theme.$black, 0.6);
    height: 100%;
    display: flex;
    align-items: center;
    & > p {
      margin-bottom: 8px;
      max-height: 32px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@media only screen and (max-width: 800px) {
  .root{
    max-width: 600px;
  }
}
.isBlur{
  filter: blur(3px);
}