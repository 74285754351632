@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: theme.$font-size-12;

  .separator {
    color: theme.$black;
    margin-top: 3px;
  }

  .listItem {
    font-size: theme.$font-size-12;
    color: theme.$black;
    font-weight: theme.$font-weight-light;
    line-height: 2;
    text-decoration: none;

    &:hover {
      color: theme.$black;
    }
  }

  .active {
    font-weight: bold;
    margin-top: 1px;
  }
}