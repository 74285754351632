@use 'styles/theme/theme' as theme;

.title {
  margin-bottom: 28px;
  line-height: 0.7;

  @media screen and (max-width: 1024px) {
    margin-bottom: 16px;
    padding: 0 16px 0 0px;
  }
}

.actions {
  justify-content: flex-end !important;
  gap: 8px;
  position: relative !important;
}

.searchBtn {
  width: 200px;
}

.clearBtn {
  color: theme.$secondaryGrey;
}

.wrapperMobile {
  top: 0 !important;
  padding: 16px;
  margin-top: 0px !important;
}

.wrapper {
  top: 0 !important;
  margin-top: 0px;
}


.performanceSearchMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    &::before,
    &::after {
      display: inline-block;
      vertical-align: middle;
      content: "";
      border-top: 1px solid theme.$tertiaryGrey;
      width: 100%;
      margin: 0 10px;
    }
  }

  &__btn {
    color: theme.$tertiaryGrey;
    padding: 16px 42px;
    justify-content: space-between;
    @media screen and (max-width: 530px) {
      padding: 16px;
    }
  }
}

.modal {
  max-height: 90%;
}