@import 'styles/theme/theme';

.root {
  width: fit-content;
  position: relative;
}

.link {
  width: 100%;
}

.radioLabel {
  color: $primaryGrey !important;
  font-size: $font-size-12;
  font-weight: $font-weight-regular !important;
  &__isSelected {
    font-weight: $font-weight-bold !important;
  }
}

.toggleBtn {
  display: inline-flex;
  align-items: center;
  border: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: $font-size-14;
  padding: 12px 14px;
  font-family: $base-font-family;
  min-width: 180px;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: $white;
    .radioLabel {
      font-weight: $font-weight-bold !important;
    }
  }
}

.selectedLabel {
  margin: 0 5px;
}

.radioButton {
  min-width: auto;
  max-width: auto;
  margin: 0;
  padding: 0;
}

.selectedOption {
  display: none;
  border-radius: $border-radius-medium;
  background-color: $white;
  border: 1px solid $tertiaryGrey;

  svg {
    margin-right: 0;
  }

  &__dropdown {
    display: flex;
  }

  &__withoutLabel {
    min-width: unset;
    width: unset;
    svg {
      margin: 0;
    }
  }

  &__isActive {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
  }

  &:hover {
    border: 1px solid $primaryGrey;
    background-color: $white;
  }
}

.darkTheme {
  &.toggleBtn {
    background-color: rgba($black, 0.6);
    color: $white;

    svg {
      fill: $white;
      margin-right: 5px;
    }

    &:hover {
      background: rgba($black, 1);
      color: $white;
      svg {
        fill: $white;
      }
    }

    &__isSelected {
    background-color: rgba($black, 1);
    color: $white;
      svg {
        fill: $white;
      }
    }
  }
  
  .selectedOption {
    background-color: transparent;
    &__isActive {
      background-color: transparent;
    }
  }
}

.drawer {
  padding: 0px;
  &__header {
    padding: 16px;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $borderGrey;
    
    &_darkTheme {
      background-color: $white;
      border-bottom: 1px solid $borderGrey;
    }
  }
  &__title {
    margin-left: 10px;
    width: 100%;
  }
  &__darkMode { 
    background-color: $white;
  }
  &__darkMode {
    background-color: $white;
  }
}

.optionsList {
  width: 100%;
  display: flex;
  border-radius: $border-radius-small;

  & > * {
    width: 100%;
    border-radius: 0;
  }

  a:last-child > .toggleBtn,  label:last-child.toggleBtn, button:last-child.toggleBtn{
    border-bottom-right-radius: $border-radius-medium;
    border-bottom-left-radius: $border-radius-medium;
  }

  &.isDropdown {
    display: none;
    position: absolute;
    flex-direction: column;
    right: 0;
    top: 100%;
    z-index: 10;
    align-items: center;
  }

  &:not(.isDropdown) {
    .toggleBtn {
      width: unset;
      min-width: unset;
    }
    .toggleBtn:first-child {
      border-top-left-radius: $border-radius-small;
      border-bottom-left-radius: $border-radius-small;
    }
    .toggleBtn:last-child {
      border-top-right-radius: $border-radius-small;
      border-bottom-right-radius: $border-radius-small;
    }
  }

  &.isOpen {
    display: flex;
  }

  &:not(.darkTheme) {
    background-color: $white;
    border: 1px solid $borderGrey;
    border-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  &:is(.darkTheme) {
    border: none;
  }
}

@media (max-width: 900px) and (min-width: 600px) {
  .optionsList {
    display: none;
    position: absolute;
    flex-direction: column;
    right: 0;
    top: 100%;
    z-index: 10;

    &:not(.isDropdown) {
      .toggleBtn {
        width: 160px;
      }
      .toggleBtn:first-child {
        border-top-left-radius: $border-radius-medium;
        border-bottom-left-radius: 0;
      }
      .toggleBtn:last-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: $border-radius-medium;
        border-bottom-left-radius: $border-radius-medium;
      }
    }
  }
}

@media (max-width: 600px) {
  .optionsList {
    position: relative;
    align-items: center;
    flex-direction: column;
    border: 0;
    gap: 16px;
    padding: 16px;

    &.isDropdown {
      position: relative;

      label:last-child.toggleBtn, button:last-child.toggleBtn{
        border-bottom-right-radius: $border-radius-small;
        border-bottom-left-radius: $border-radius-small;
      }
      
      a:last-child {
        .toggleBtn {
          border-bottom-right-radius: $border-radius-small;
          border-bottom-left-radius: $border-radius-small;
        }
      }

    }
  
    &:not(.isDropdown) {
      .toggleBtn {
        width: 100%;
        border-radius: $border-radius-small;
      }
    }

    .toggleBtn {
      border: 1px solid $tertiaryGrey;
      background-color: $white;
      color: $secondaryGrey;
      width: 100%;
      max-width: 100%;
      border-radius: $border-radius-small;
      svg {
        fill: $secondaryGrey;
      }

      &:hover {
        border: 1px solid $primaryGrey;
        background-color: $white;
      }

      &__isSelected {
        background-color: $white;
        color: $primaryGrey;
        border: 1px solid $primaryGrey;

        &:is(:darkTheme) {
          background-color: $white;
          color: $white;
          border: 1px solid $blue;
          svg {
            fill: $blue;
          }
        }
      }
    }
  }

  .selectedOption {
    &__isActive {
      border-bottom-left-radius: $border-radius-medium;
      border-bottom-right-radius: $border-radius-medium;
    }
  }

  .darkTheme {
    .toggleBtn {
      &:hover {
        background-color: $blueLight;
        color: $blue;
        border: 1px solid $blueLight;
        svg {
          fill: $blue;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .selectedOption {
    display: flex;
  }
}
