@use 'styles/theme/theme' as theme;

.root {
  @include theme.container($tabPadding: 0, $mobilePadding: 0);
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) and (max-width: 1280px) {
    padding: 0 16px;
  }

  &__breadcrumbs {
    & > div {
      padding-top: 0;
      ul {
        margin-top: 18px;
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 0 16px;
    }
  }
}
