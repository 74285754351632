@use 'styles/theme/theme' as theme;

.navigation {
  white-space: nowrap;
  width: 100%;

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;

    &_item {
      & + & {
        margin-left: 14px;
      }
    }
  }

  .navItems {
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .scrollbarRoot {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.navLink {
  padding: 6px 0;
  display: inline-block;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  color: var(--current-theme-primary-text-color);
  &:hover {
    color: var(--current-theme-primary-text-color);
    border-bottom-color: var(--current-theme-contrast-border-color);
  }

  &.isActive {
    color: var(--current-theme-primary-text-color);
    border-bottom-color: var(--current-theme-contrast-border-color);
  }
}
