@import 'styles/theme/theme';

.modalRoot {
  padding-top: 100px;
}

.modalBody {
  padding:  0 20px 20px 20px;
  &::before {
    content: "";
    display: block;
    border: 1px solid $tertiaryGrey;
    width: 100%;
    height: 2px;
    margin-bottom: 16px;
    box-shadow: 0 0 0 100vmax $tertiaryGrey;
    clip-path: inset(0 -100vmax)
  }
 &__form {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    &_field {
      margin-bottom: 16px;
      :global {
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border: 1px solid $blue;
        }
      }
      div {
        border: none;
      }
    }
    &_actions {
      &::before {
        content: "";
        display: block;
        border: 1px solid $tertiaryGrey;
        width: 100%;
        height: 2px;
        margin: 16px 0px;
        box-shadow: 0 0 0 100vmax $tertiaryGrey;
        clip-path: inset(0 -100vmax)
      }
      &_buttons {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        &_share {
          margin-left: 10px;
        }
      }
    }
  }
}

.entityShareAccess {
  &__button {
    color: $primaryGrey;

    &_iconOnly {
      padding: 8px;
      width: fit-content !important;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    fill: $primaryGrey;
    transform: scaleX(-1);
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__link {
    color: $primaryGrey;
  }
}

.continueBtn {
  margin-top: 10px;
}
