@import 'styles/theme/theme';

.btn {
  background: $white;
  display: inline-flex;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: middle;
  width: 38px;
  height: 38px;
  font-family: 'Roboto';
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: $font-size-14;
  line-height: 16px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  outline: none;

  &.primary {
    background: $blue;
    border: 1px solid $blue;
    color: white;
    &:hover{
      box-shadow: $hoverBoxShadow;
    }
    &:active {
      box-shadow: $activeBoxShadow;
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
      box-shadow: none;
    }
  }

  &.secondary {
    background: $white;
    border: 1px solid $tertiaryGrey;
    color: $blue;

    &:hover{
      color: $blue;
      box-shadow: $hoverBoxShadow;
      border: 1px solid $blue;
      background: $white;
    }
    &:active {
      box-shadow: $activeIconBoxShadow;
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
      box-shadow: none;
      background: $white;
      opacity: 0.5;
      border: 1px solid $tertiaryGrey;
    }
  }

  &.tertiary {
    background: $white;
    color: 383838;

    &:hover{
      color: $blue;
      box-shadow: $hoverBoxShadow;
      background: $blueLight;
      border: none;
    }
    &:disabled {
      opacity: 0.5;
      color: #383838;
      cursor: default;
      box-shadow: none;
      background: $white;
      opacity: 0.5;
    }
  }
  &.quaternary {
    background: transparent;
    color: $blue;

    &:hover{
      color: $white;
      box-shadow: $hoverBoxShadow;
      background: $blue;
      border: none;

      & > svg{
        fill: $white;
      }
    }

    & > svg{
      fill: $blue;
    }
  }
  &.cuetv {
    background: $red;
    border: 1px solid $red;
    color: white;
    &:hover {
      box-shadow: $hoverCueTvBoxShadow;
    }
    &:active {
      box-shadow: $activeBoxShadow;
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
      box-shadow: none;
    }
  }

}
