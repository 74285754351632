@import 'styles/theme/theme';

.daysButton {
  height: 38px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding: 12px;
  border: 1px solid $tertiaryGrey;
  border-radius: 8px;
  background: $white;
  color: $secondaryGrey;
  cursor: pointer;
  &:hover,
  &:focus {
    border-color: $blue;
    background-color: hsl(208, 100%, 97%);
    color: $blue;
  }
}

.dateWrap {
  display: flex;
  flex-direction: column;
  
  .dateSelector {
    display: block;
  }
  
  .dateButtons {
    button {
      margin-top: 5px;
      margin-right: 8px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .dateWrap {
    margin-bottom: 20px;
  }
}