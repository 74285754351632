$black: rgb(30, 29, 31);
$black2: rgb(46, 45, 49);
$blue2: hsl(208, 100%, 90%);
$blue3: rgb(0, 103, 197);
$blue: rgb(4, 145, 255);
$blueChip: rgb(0, 103, 197);
$borderBlackSeparator: rgb(102, 102, 102);
$cardTitle: rgb(95, 95, 95);
$contentDivider: rgb(151, 151, 151, 0.2);
$darkBlue: rgb(0, 82, 204);
$darkText: rgb(58, 58, 58);
$grayCard: rgb(245, 245, 245);
$grey1: rgb(223, 223, 223);
$grey3: rgb(58, 58, 58);
$grey5: rgb(247, 247, 247);
$lightGrey: rgb(151, 151, 151, 0.2);
$lightGrey1: rgb(151, 151, 151);
$lightGrey3: rgb(241, 241, 241);
$lightPink: rgb(253, 245, 245);
$lightPrimary: rgb(243, 109, 42, 0.1);
$optionBg: rgb(0, 0, 0, 0.04);
$orange: rgb(243, 110, 42);
$primaryTitleColor: rgb(47, 47, 47);
$red: rgb(217, 43, 47);
$red5: rgb(207, 20, 43);
$text: rgb(139, 139, 139);
$text: rgb(139, 139, 139);
$textGray: rgba(0, 0, 0, 0.38);
$title: rgb(45, 45, 45);
$white: #ffffff;
