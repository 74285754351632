@import 'styles/colors';
@import 'styles/mixins';

.root {
  font-size: 50px;
  color: $title;
  display: inline-block;
  & * {
    font-family: inherit;
    color: inherit;
    font-size: inherit;
  }

  @include down('xs') {
    font-size: 35px;
  }
}
