.root {
  display: grid;
  grid-template-columns: auto minmax(240px, 260px) 240px 240px;
  grid-gap: 16px;

  &.concise {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
}
