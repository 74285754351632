@use 'styles/theme/theme' as theme;

.icon {
  fill {
    color: theme.$blue !important;
  }
}

.sectionBlock {
  @media screen and (max-width: 1024px) {
    padding: 0 8px; 
  }
  @media screen and (max-width: 600px) {
    padding: 0; 
  }
}

.seeAllPerformances {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 16px;
  border: 1px solid theme.$borderGrey;
  color: theme.$blue !important;
}
