@use 'styles/theme/theme' as theme;

.carousel {
  ul {
    li > div:first-child {
      width: 100%;
    }
  }
}

.banner {
  position: relative;
  background: theme.$orangeLight;
  justify-content: space-around;
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 220px;

  &__bgBlurredShade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 489.277px;
    transform: translate(50%, 0%);
    background: theme.$white;
    filter: blur(69.1500015258789px);
  }

  &__image {
    z-index: 1;
  }

  &__textArea {
    z-index: 1;
    align-self: center;
    &_title {
      margin-bottom: 20px;
    }
    &_subTitle {
      text-transform: uppercase;
    }
  }
}

.bannerReverse {
  flex-direction: row-reverse;
}

.rightArrow,
.leftArrow {
  top: -10%;
}

.rightArrow {
  right: 20px;
  background-color: theme.$black;
  border: 1px solid theme.$white;

  svg {
    margin-top: 1px;
    margin-left: 1px;
  }

  @media screen and (max-width: 600px) {
    right: 10px;
  }
}

.leftArrow {
  left: 20px;
  background-color: theme.$black;
  border: 1px solid theme.$white;

  svg {
    margin-top: 1px;
    margin-left: 1px;
  }

  @media screen and (max-width: 600px) {
    left: 10px;
  }
}
