@use 'styles/theme/theme' as theme;

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  gap: 12px;

  &__emptyLeftActions {
    justify-content: flex-end;

    @media screen and (max-width: 1280px) {
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 1280px) {
    flex-wrap: wrap;
  }
}

.leftSide {
  display: flex;
  gap: 12px;

  &__hidden {
    display: none;
  }

  &__shareEntity {
    span:first-child {
      width: 100%;
      border-radius: 64px;
      padding: 9px 12px;
    }
  }
}

.rightSide {
  display: flex;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: 1280px) {
    flex-wrap: wrap;

    &__castingToolPromo {
      display: none;
    }

    &__editBtn {
      order: -3;

      .editAction {
        min-width: 90px;
      }
    }

    &__shareAccess {
      order: -1;
    }
  }
}

@media only screen and (max-width: 600px) {
  .shareEntityListItem {
    span {
      width: 100%;
      background: none;
    }
  }
  .editListItem {
    min-width: 30px;
    color: theme.$primaryGrey;
    span {
      svg {
        fill: theme.$primaryGrey !important;
      }
    }
  }
}
